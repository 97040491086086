<template>
     <div class="mainWrapper pb-0">
        <AppLoader v-if="loader" />
        <section class="blockElement space position-relative reatesPage" v-if="show && store.flavordata.flavorId">
            <div class="container">
                <vue-pdf-embed :source="`${static_vars.domainURL}assets/pdf/legal/TOS-Global.pdf`"  v-show="!store.flavordata?.isEu" @loaded="Load" />
                <vue-pdf-embed :source="`${static_vars.domainURL}assets/pdf/legal/TOS-EU.pdf`"  v-show="store.flavordata?.isEu" @loaded="Load" />
               <!--  <div class="row">
                    <div class="col-12" v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId">
                        <div class="flex-between">
                            <h1 class="mb-2 f-22">{{(store.flavordata.flavorId == 1) ? $t('TOS.heading') : $t('TOSEU.heading')}}
                            </h1>
                        </div>
                    </div>
                    <div class="col-12" v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId">
                        
                        <div class="static"  v-if="store.flavordata.flavorId == 1">
                            <router-link to="/terms-of-service-eu" class="link">Click here for the Terms of Service for ZuluTrade EU</router-link>
                            <p>{{$t('TOS.gText1')}}</p>
                            <h3>{{$t('TOS.gText2')}}</h3>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText3')}}</h5>
                            <p>{{$t('TOS.gText4')}}</p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText5')}}</h5>
                            <p>{{$t('TOS.gText6')}}</p>
                            <p>{{$t('TOS.gText7')}}</p>
                            <p>{{$t('TOS.gText8')}}</p>
                            <p>{{$t('TOS.gText9')}}</p>
                            <p> {{$t('TOS.gText10')}}</p>
                            <p>{{$t('TOS.gText11')}}
                            </p>
                            <p>{{$t('TOS.gText12')}}</p>
                            <h3 class="headerTerms">{{$t('TOS.gText13')}}</h3>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText14')}}</h5>
                            <p>{{$t('TOS.gText15')}} <a href="http://www.zulutrade.com">www.zulutrade.com</a>{{$t('TOS.gText16')}}</p>
                            <p>{{$t('TOS.gText17')}}</p>
                            <p>{{$t('TOS.gText18')}}
                            </p>
                            <p>{{$t('TOS.gText19')}}</p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText20')}}</h5>
                            <p>{{$t('TOS.gText21')}} </p>
                            <p>{{$t('TOS.gText22')}}</p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText23')}}</h5>
                            <p>{{$t('TOS.gText51')}}</p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText24')}}</h5>
                            <p>{{$t('TOS.gText25')}}</p>
                            <p>{{$t('TOS.gText26')}}</p>
                            <p>{{$t('TOS.gText27')}}</p>
                            <p>{{$t('TOS.gText28')}}
                            </p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText29')}}</h5>
                            <p>{{$t('TOS.gText30')}}</p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText31')}}</h5>
                            <p>{{$t('TOS.gText32')}}</p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText33')}}</h5>
                            <p>{{$t('TOS.gText34')}}</p>
                            <p>{{$t('TOS.gText35')}}</p>
                            <p> {{$t('TOS.gText36')}}</p>
                            <p>{{$t('TOS.gText37')}}</p>
                            <p>{{$t('TOS.gText38')}}</p>
                            <p>{{$t('TOS.gText39')}}</p>
                            <p>{{$t('TOS.gText40')}}</p>
                            <p>{{$t('TOS.gText41')}}</p>
                            <p>{{$t('TOS.gText42')}}</p>
                            <p>{{$t('TOS.gText43')}}</p>
                            <p>{{$t('TOS.gText44')}} <strong> {{$t('TOS.gText45')}}</strong> {{$t('TOS.gText46')}} </p>
                            <p>{{$t('TOS.gText47')}} </p>
                            <p>{{$t('TOS.gText48')}}</p>
                            <p>{{$t('TOS.gText49')}} </p>
                            <p> {{$t('TOS.gText50')}}</p>
                            <p class="marginLeftTerms">{{$t('TOS.gText52')}} </p>
                            <p class="marginLeftTerms">{{$t('TOS.gText53')}}</p>
                            <p class="marginLeftTerms">{{$t('TOS.gText54')}}</p>
                            <p class="marginLeftTerms">{{$t('TOS.gText55')}}</p>
                            <p class="marginLeftTerms">{{$t('TOS.gText56')}}
                            </p>
                            <p>{{$t('TOS.gText57')}}
                                <a
                                    href="https://www.zulutrade.com/risk-disclaimer">https://www.zulutrade.com/risk-disclaimer</a>{{$t('TOS.gText58')}}
                            </p>
                            <p>{{$t('TOS.gText59')}}</p>
                            <p>{{$t('TOS.gText60')}}</p>
                            <p>{{$t('TOS.gText61')}}
                               </p>
                            <p>{{$t('TOS.gText62')}}</p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText63')}}</h5>
                            <p>{{$t('TOS.gText64')}}</p>
                            <p>{{$t('TOS.gText65')}}</p>
                            <p>{{$t('TOS.gText66')}}</p>
                            <p> {{$t('TOS.gText67')}}<a
                                    href="https://www.zulutrade.com/Static/Files/TermsAgreements/MarketDataAddendum.pdf">{{$t('TOS.gText68')}}</a>.
                            </p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText69')}}</h5>
                            <p>{{$t('TOS.gText70')}}</p>
                            <h3 class="headerTerms">{{$t('TOS.gText71')}} </h3>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText72')}}</h5>
                            <p>{{$t('TOS.gText73')}}</p>
                            <p> {{$t('TOS.gText74')}}</p>
                            <p>{{$t('TOS.gText75')}}</p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText76')}}</h5>
                            <p>{{$t('TOS.gText77')}}</p>
                            <p class="marginLeftTerms">{{$t('TOS.gText78')}} </p>
                            <p class="marginLeftTerms">{{$t('TOS.gText79')}}</p>
                            <p class="marginLeftTerms">{{$t('TOS.gText80')}} </p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText81')}} </h5>
                            <p>{{$t('TOS.gText82')}} </p>
                            <p>{{$t('TOS.gText83')}}</p>
                            <p>{{$t('TOS.gText84')}}</p>
                            <p>{{$t('TOS.gText85')}} <a
                                    href="https://www.zulutrade.com/help-center">https://www.zulutrade.com/support</a>
                            </p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText99')}}</h5>
                            <p>{{$t('TOS.gText86')}}</p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText87')}}</h5>
                            <p>{{$t('TOS.gText88')}}</p>
                            <h3 class="headerTerms">{{$t('TOS.gText89')}}</h3>
                            <h5 class="headerTerms">{{$t('TOS.gText90')}}</h5>
                            <p>{{$t('TOS.gText91')}}</p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText92')}}</h5>
                            <p>{{$t('TOS.gText93')}}</p>
                            <p> {{$t('TOS.gText94')}}</p>
                            <p>{{$t('TOS.gText95')}}</p>
                            <p>{{$t('TOS.gText96')}}</p>
                            <p>{{$t('TOS.gText97')}}</p>
                            <p>{{$t('TOS.gText73')}} <a
                                    href="http://www.zulutrade.com/file/common/lods/agreement_on_the_Storage_of_the_Cardholders_Credentials_-COF_Agreement.pdf">{{$t('TOS.gText68')}}</a>
                            </p>
                            <h3 class="headerTerms">{{$t('TOS.gText100')}}</h3>
                            <p>{{$t('TOS.gText101')}}</p>
                            <p class="marginLeftTerms">{{$t('TOS.gText102')}}</p>
                            <p class="marginLeftTerms">{{$t('TOS.gText103')}}</p>
                            <p class="marginLeftTerms">{{$t('TOS.gText104')}}
                            </p>
                            <p class="marginLeftTerms">{{$t('TOS.gText105')}}</p>
                            <h3 class="headerTerms">{{$t('TOS.gText106')}}</h3>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText107')}}</h5>
                            <p>{{$t('TOS.gText108')}} </p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText109')}}</h5>
                            <p>{{$t('TOS.gText110')}}</p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText111')}}</h5>
                            <p>{{$t('TOS.gText112')}}</p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText113')}}</h5>
                            <p> {{$t('TOS.gText114')}}<a
                                    href="https://www.zulutrade.com/privacy-policy">https://www.zulutrade.com/privacy-policy</a>
                                {{$t('TOS.gText115')}} </p>
                            <h3 class="headerTerms">{{$t('TOS.gText116')}}</h3>
                            <p>{{$t('TOS.gText117')}}</p>
                            <p>{{$t('TOS.gText118')}}</p>
                            <p>{{$t('TOS.gText119')}}</p>
                            <p>{{$t('TOS.gText120')}}</p>
                            <p>{{$t('TOS.gText121')}}</p>
                            <p>{{$t('TOS.gText122')}}</p>
                            <p style="font-style: italic;">* {{$t('TOS.gText123')}}</p>
                            <h3 class="headerTerms">{{$t('TOS.gText124')}}</h3>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText125')}}</h5>
                            <p>{{$t('TOS.gText126')}}</p>
                            <p class="marginLeftTerms">{{$t('TOS.gText127')}}</p>
                            <p class="marginLeftTerms">{{$t('TOS.gText128')}}</p>
                            <p class="marginLeftTerms">{{$t('TOS.gText129')}}</p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText130')}}</h5>
                            <p>{{$t('TOS.gText131')}} </p>
                            <p>{{$t('TOS.gText132')}} </p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText133')}} </h5>
                            <p>{{$t('TOS.gText134')}}</p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText135')}}</h5>
                            <p>{{$t('TOS.gText136')}} </p>
                            <p>{{$t('TOS.gText137')}}</p>
                            <h5 class="subHeaderTerms">{{$t('TOS.gText138')}}</h5>
                            <p>{{$t('TOS.gText139')}}</p>
                            <p>{{$t('TOS.gText140')}} </p>
                            <p>{{$t('TOS.gText141')}}</p>
                            <p>{{$t('TOS.gText142')}}</p>
                            <p>{{$t('TOS.gText143')}}</p>
                            
                        <div class="static" v-if="store.flavordata.flavorId == 38">
                            <router-link class="link mb-2 d-inline-block" to="/terms-of-service">Click here for the Terms of Service</router-link>
                            <h5>{{$t('TOSEU.eText1')}}</h5>
                            <p>{{$t('TOSEU.eText2')}}</p>
                            <h5>{{$t('TOSEU.eText2_2')}}</h5>
                            <p>{{$t('TOSEU.eText3')}} (<a class="link" target="_blank" href="https://aaafx.com/Assets/AAAFx/ConflictsOfInterestPolicy.pdf">{{$t('TOSEU.eText4')}}</a>) {{$t('TOSEU.eText5')}} (<a class="link" target="_blank" href="https://aaafx.com/privacy?Lang=en-US">{{$t('TOSEU.eText6')}}</a>) {{$t('TOSEU.eText7')}}</p>
                            <p>
                                
                            </p>
                            <p>{{$t('TOSEU.eText7')}}</p>
                            
                            <p>{{$t('TOSEU.eText8')}}</p>
                            <p>{{$t('TOSEU.eText9')}}</p>
                            <p>{{$t('TOSEU.eText10')}}</p>
                            <p>{{$t('TOSEU.eTexT11')}}</p>
                            <p>{{$t('TOSEU.eText12')}}</p>
                            <h5>{{$t('TOSEU.eText13')}}</h5>
                            <p>{{$t('TOSEU.eText14')}}</p>
                            <h5>{{$t('TOSEU.eText15')}}</h5>
                            <p>{{$t('TOSEU.eText16')}}</p>
                            <h5>{{$t('TOSEU.eText17')}}</h5>
                            <p>{{$t('TOSEU.eText18')}}</p>
                            <p>{{$t('TOSEU.eText19')}}</p>
                            <p>{{$t('TOSEU.eText20')}}(
                                <a class="link" target="_blank" href="https://aaafx.com/Assets/AAAFx/ConflictsOfInterestPolicy.pdf">{{$t('TOSEU.eText21')}}</a>). {{$t('TOSEU.eText22')}}
                            </p>
                            <h5>{{$t('TOSEU.eText23')}}</h5>
                            <b>{{$t('TOSEU.eText24')}}</b>
                            <p>{{$t('TOSEU.eText25')}} <a href="www.zulutrade.com">{{$t('TOSEU.eText25_1')}}</a>{{$t('TOSEU.eText25_2')}}</p>
                            <p>{{$t('TOSEU.eText26')}}</p>
                            <p>{{$t('TOSEU.eText27')}}</p>
                            <b>{{$t('TOSEU.eText28')}}</b>
                            <p>{{$t('TOSEU.eText29')}}</p>
                            <b>{{$t('TOSEU.eText30')}}</b>
                            <p>{{$t('TOSEU.eText31')}}</p>
                            <b>{{$t('TOSEU.eText32')}}</b>
                            <p>{{$t('TOSEU.eText33')}}</p>
                            <p>{{$t('TOSEU.eText34')}}</p>
                            <p>{{$t('TOSEU.eText35')}}</p>
                            <b>{{$t('TOSEU.eText36')}}</b>
                            <p>{{$t('TOSEU.eText37')}}</p>
                            <ul class="listCircle">
                                <li>{{$t('TOSEU.eText38')}}</li>
                                <li>{{$t('TOSEU.eText39')}}</li>
                            </ul>
                            {{$t('TOSEU.eText40')}}
                            <b>{{$t('TOSEU.eText41')}}</b>
                            <p>{{$t('TOSEU.eText42')}}</p>
                            <b>{{$t('TOSEU.eText43')}}</b>
                            <p>{{$t('TOSEU.eText44')}}</p>
                            <p>{{$t('TOSEU.eText45')}}</p>
                            <p>{{$t('TOSEU.eText46')}}</p>
                            <ul class="listCircle">
                                <li>{{$t('TOSEU.eText47')}}</li>
                                <li>{{$t('TOSEU.eText48')}}</li>
                            </ul>
                            <b>{{$t('TOSEU.eText49')}}</b>
                            <b>{{$t('TOSEU.eText49_1')}}</b>
                            <ul class="listCircle">
                                <li>{{$t('TOSEU.eText50')}}</li>
                                <li>{{$t('TOSEU.eText51')}}</li>
                                <li>{{$t('TOSEU.eText52')}}</li>
                                <li>{{$t('TOSEU.eText52_1')}}</li>
                                <li>{{$t('TOSEU.eText53')}}</li>
                            </ul>
                            <p>{{$t('TOSEU.eText54')}}</p>
                            <p>{{$t('TOSEU.eText55')}}</p>
                            <p>{{$t('TOSEU.eText56')}}</p>
                            <p>{{$t('TOSEU.eText57')}}</p>
                            <p>{{$t('TOSEU.eText58')}}</p>
                            <p>{{$t('TOSEU.eText59')}}</p>
                            <p>{{$t('TOSEU.eText60')}}</p>
                            <p>{{$t('TOSEU.eText61')}}</p>
                            <p>{{$t('TOSEU.eText62')}}
                                <a class="link" target="_blank" href="https://aaafx.com/Assets/AAAFx/RiskDisclaimer.pdf">{{$t('TOSEU.eText63')}}</a>, {{$t('TOSEU.eText64')}}
                            </p>
                            <p>{{$t('TOSEU.eText65')}}</p>
                            <p>{{$t('TOSEU.eText66')}}</p>
                            <p>{{$t('TOSEU.eText68')}}</p>
                            <p>{{$t('TOSEU.eText69')}}</p>
                            <h5>{{$t('TOSEU.eText70')}}</h5>
                            <b>{{$t('TOSEU.eText71')}}</b>
                            <p>{{$t('TOSEU.eText72')}}</p>
                            <p>{{$t('TOSEU.eText73')}}</p>
                            <p>{{$t('TOSEU.eText74')}}</p>
                            <b>{{$t('TOSEU.eText75')}}</b>
                            <p>{{$t('TOSEU.eText76')}}</p>
                            <p>{{$t('TOSEU.eText77')}}</p>
                            <p>{{$t('TOSEU.eText78')}}</p>
                            <p>{{$t('TOSEU.eText79')}}</p>
                            <p>{{$t('TOSEU.eText80')}}</p>
                            <p>{{$t('TOSEU.eText81')}}</p>
                            <p>{{$t('TOSEU.eText82')}}</p>
                            <p>{{$t('TOSEU.eText83')}}</p>
                            <h5>{{$t('TOSEU.eText84')}}</h5>
                            <b>{{$t('TOSEU.eText85')}}</b>
                            <p>{{$t('TOSEU.eText86')}}</p>
                            <ul class="listCircle">
                                <li>{{$t('TOSEU.eText87')}}</li>
                                <li>{{$t('TOSEU.eText88')}}
                                    <a class="link" target="_blank" href="https://aaafx.com/privacy">{{$t('TOSEU.eText89')}}</a>{{$t('TOSEU.eText90')}}
                                </li>
                            </ul>
                            <p>{{$t('TOSEU.eText91')}}</p>
                            <p>{{$t('TOSEU.eText92')}}</p>
                            <b>{{$t('TOSEU.eText93')}}</b>
                            <p>{{$t('TOSEU.eText94')}}</p>
                            <b>{{$t('TOSEU.eText95')}}</b>
                            <p>{{$t('TOSEU.eText96')}}</p>
                            <p>{{$t('TOSEU.eText97')}}</p>
                            <p>{{$t('TOSEU.eText98')}}</p>
                            <p>{{$t('TOSEU.eText99')}}</p>
                            <b>{{$t('TOSEU.eText100')}}</b>
                            <p>{{$t('TOSEU.eText101')}}</p>
                            <p>{{$t('TOSEU.eText102')}}</p>
                            <p>{{$t('TOSEU.eText103')}}</p>
                            <p>{{$t('TOSEU.eText104')}}</p>
                            <p>{{$t('TOSEU.eText105')}}</p>
                            <b>{{$t('TOSEU.eText106')}}</b>
                            <p>{{$t('TOSEU.eText107')}}</p>
                            <b>{{$t('TOSEU.eText108')}}</b>
                            <p>{{$t('TOSEU.eText109')}}</p>
                            <h5>{{$t('TOSEU.eText110')}}</h5>
                            <b>{{$t('TOSEU.eText111')}}</b>
                            <p>{{$t('TOSEU.eText112')}}</p>
                            <p>{{$t('TOSEU.eText113')}}</p>
                            <p>{{$t('TOSEU.eText114')}}</p>
                            <p>{{$t('TOSEU.eText115')}}</p>
                            <p>{{$t('TOSEU.eText116')}}</p>
                            <p>{{$t('TOSEU.eText117')}}</p>
                            <b>{{$t('TOSEU.eText118')}}</b>
                            <p>{{$t('TOSEU.eText119')}}</p>
                            <p>{{$t('TOSEU.eText120')}}</p>
                            <p>{{$t('TOSEU.eText121')}}</p>
                            <p>{{$t('TOSEU.eText122')}}</p>
                            <p>{{$t('TOSEU.eText123')}}</p>
                            <p>{{$t('TOSEU.eText124')}}
                                <a class="link" target="_blank" href="assets/pdf/agreement_on_the_Storage_of_the_Cardholders_Credentials_-COF_Agreement.pdf">{{$t('TOSEU.eText125')}}</a>
                            </p>
                            <b>{{$t('TOSEU.eText126')}}</b>
                            <p>{{$t('TOSEU.eText127')}}</p>
                            <b>{{$t('TOSEU.eText128')}}</b>
                            <p>{{$t('TOSEU.eText129')}}</p>
                            <p>{{$t('TOSEU.eText130')}}</p>
                            <p>{{$t('TOSEU.eText131')}}</p>
                            <p>{{$t('TOSEU.eText132')}}</p>
                            <p>{{$t('TOSEU.eText133')}}</p>
                            <p>{{$t('TOSEU.eText134')}}</p>
                            <p>{{$t('TOSEU.eText135')}}<router-link to="/affiliate-guide" class="link" target="_blank">{{$t('TOSEU.eText136')}}</router-link>
                            </p>
                            <b>{{$t('TOSEU.eText137')}}</b>
                            <p>{{$t('TOSEU.eText138')}}</p>
                            <p>{{$t('TOSEU.eText139')}}</p>
                            <h5>{{$t('TOSEU.eText140')}}</h5>
                            <p>{{$t('TOSEU.eText141')}}</p>
                            <p>{{$t('TOSEU.eText142')}}</p>
                            <p>{{$t('TOSEU.eText143')}}</p>
                            <p>{{$t('TOSEU.eText144')}}</p>
                            <p>{{$t('TOSEU.eText145')}}</p>
                            <h5>{{$t('TOSEU.eText146')}}</h5>
                            <b>{{$t('TOSEU.eText147')}}</b>
                            <p>{{$t('TOSEU.eText148')}}</p>
                            <b>{{$t('TOSEU.eText149')}}</b>
                            <p>{{$t('TOSEU.eText150')}}</p>
                            <b>{{$t('TOSEU.eText151')}}</b>
                            <p>{{$t('TOSEU.eText152')}}</p>
                            <b>{{$t('TOSEU.eText153')}}</b>
                            <p>{{$t('TOSEU.eText154')}}</p>
                            <b>{{$t('TOSEU.eText155')}}</b>
                            <p>{{$t('TOSEU.eText156')}}
                                <a class="link" target="_blank" href="https://aaafx.com/privacy">{{$t('TOSEU.eText157')}}</a> {{$t('TOSEU.eText158')}}
                            </p>
                            <h5>{{$t('TOSEU.eText159')}}</h5>
                            <b>{{$t('TOSEU.eText160')}}</b>
                            <p>{{$t('TOSEU.eText161')}}</p>
                            <b>{{$t('TOSEU.eText162')}}</b>
                            <p>{{$t('TOSEU.eText163')}}</p>
                            <p>{{$t('TOSEU.eText164')}}</p>
                            <p>{{$t('TOSEU.eText165')}}</p>
                            <p>{{$t('TOSEU.eText166')}}</p>
                            <p>{{$t('TOSEU.eText167')}}</p>
                            <p>{{$t('TOSEU.eText168')}}</p>
                            <p>{{$t('TOSEU.eText169')}}</p>
                            <p>{{$t('TOSEU.eText170')}}</p>
                            <p>{{$t('TOSEU.eText171')}}</p>
                            <p>{{$t('TOSEU.eText172')}}</p>
                            <p>{{$t('TOSEU.eText173')}}</p>
                            <p>{{$t('TOSEU.eText174')}}</p>
                            <p>{{$t('TOSEU.eText175')}}</p>
                            <p>{{$t('TOSEU.eText176')}}</p>
                            <h5>{{$t('TOSEU.eText177')}}</h5>
                            <p>{{$t('TOSEU.eText178')}}</p>
                            <p>{{$t('TOSEU.eText179')}}</p>
                            <p>{{$t('TOSEU.eText180')}}</p>
                            <p>{{$t('TOSEU.eText181')}}</p>
                            <h5>{{$t('TOSEU.eText182')}}</h5>
                            <p><b>{{$t('TOSEU.eText183')}}</b></p>
                            <p>{{$t('TOSEU.eText184')}}</p>
                            <p>{{$t('TOSEU.eText185')}}</p>
                            <p>{{$t('TOSEU.eText186')}}</p>
                            <p>{{$t('TOSEU.eText187')}}</p>
                            <p><b>{{$t('TOSEU.eText188')}}</b></p>
                            <b>{{$t('TOSEU.eText189')}}</b>
                            <p>{{$t('TOSEU.eText190')}}</p>
                            <b>{{$t('TOSEU.eText191')}}</b>
                            <p>{{$t('TOSEU.eText192')}}</p>
                            <p>{{$t('TOSEU.eText193')}}</p>
                            <p>{{$t('TOSEU.eText194')}}</p>
                            <b>{{$t('TOSEU.eText195')}}</b>
                            <p>{{$t('TOSEU.eText196')}}</p>
                            <b>{{$t('TOSEU.eText197')}}</b>
                            <p>{{$t('TOSEU.eText198')}}</p>
                            <b>{{$t('TOSEU.eText199')}}</b>
                            <p>{{$t('TOSEU.eText200')}}</p>
                            <p>{{$t('TOSEU.eText201')}}</p>
                            <p><b>{{$t('TOSEU.eText202')}}</b></p>
                            <p>{{$t('TOSEU.eText203')}}</p>
                            <h5>{{$t('TOSEU.eText204')}}</h5>
                            <p>{{$t('TOSEU.eText205')}}</p>
                            <p>{{$t('TOSEU.eText206')}}</p>
                            <p>{{$t('TOSEU.eText207')}}</p>
                            <p>{{$t('TOSEU.eText208')}}</p>
                            <p>{{$t('TOSEU.eText209')}}</p>
                            <p>{{$t('TOSEU.eText210')}}</p>
                            <p>{{$t('TOSEU.eText211')}}</p>
                            <p>{{$t('TOSEU.eText212')}}</p>
                            <h5>{{$t('TOSEU.eText213')}}</h5>
                            <p>{{$t('TOSEU.eText214')}}</p>
                            <p>{{$t('TOSEU.eText215')}}</p>
                            <p>{{$t('TOSEU.eText216')}}</p>
                            <h5>{{$t('TOSEU.eText217')}}</h5>
                            <p>{{$t('TOSEU.eText218')}}</p>
                            <p>{{$t('TOSEU.eText219')}}</p>
                            <p>{{$t('TOSEU.eText220')}}</p>
                            <p>{{$t('TOSEU.eText221')}}</p>
                            <p>{{$t('TOSEU.eText222')}}</p>
                            <h5>{{$t('TOSEU.eText223')}}</h5>
                            <p>{{$t('TOSEU.eText224')}}</p>
                            <p>{{$t('TOSEU.eText225')}}</p>
                            <p>{{$t('TOSEU.eText226')}}</p>
                            <p>{{$t('TOSEU.eText227')}}</p>
                            <h5>{{$t('TOSEU.eText228')}}</h5>
                            <p>{{$t('TOSEU.eText229')}}</p>
                            <b>{{$t('TOSEU.eText230')}}</b>
                            <p>{{$t('TOSEU.eText231')}}</p>
                            <p>{{$t('TOSEU.eText232')}}</p>
                            <p>{{$t('TOSEU.eText233')}}</p>
                            <p>{{$t('TOSEU.eText234')}}</p>
                            <p>{{$t('TOSEU.eText235')}}</p>
                            <p>{{$t('TOSEU.eText236')}}</p>
                            <p>{{$t('TOSEU.eText237')}}</p>
                            <p>{{$t('TOSEU.eText238')}}</p>
                            <p>{{$t('TOSEU.eText239')}}</p>
                            <p>{{$t('TOSEU.eText240')}}</p>
                            <p>{{$t('TOSEU.eText241')}}</p>
                            <p>{{$t('TOSEU.eText242')}}</p>
                            <p>{{$t('TOSEU.eText243')}}</p>
                            <p>{{$t('TOSEU.eText244')}}</p>
                            <p>{{$t('TOSEU.eText245')}}</p>
                            <p>{{$t('TOSEU.eText246')}}</p>
                            <p>{{$t('TOSEU.eText247')}}</p>
                            <p>{{$t('TOSEU.eText248')}}</p>
                            <p>{{$t('TOSEU.eText249')}}</p>
                            <p>{{$t('TOSEU.eText250')}}</p>
                            <p>{{$t('TOSEU.eText251')}}</p>
                            <p>{{$t('TOSEU.eText252')}}</p>
                            <p>{{$t('TOSEU.eText253')}}</p>
                            <p>{{$t('TOSEU.eText254')}}</p>
                            <p>{{$t('TOSEU.eText255')}}</p>
                            <p>{{$t('TOSEU.eText256')}}</p>
                            <b>{{$t('TOSEU.eText257')}}</b>
                            <p>{{$t('TOSEU.eText258')}}</p>
                            <b>{{$t('TOSEU.eText259')}}</b>
                            <p>{{$t('TOSEU.eText260')}}</p>
                            <b>{{$t('TOSEU.eText261')}}</b>
                            <p>{{$t('TOSEU.eText262')}}</p>
                            <b>{{$t('TOSEU.eText263')}}</b>
                            <p>{{$t('TOSEU.eText264')}}</p>
                            <b>{{$t('TOSEU.eText265')}}</b>
                            <p>{{$t('TOSEU.eText266')}}</p>
                            <b>{{$t('TOSEU.eText267')}}</b>
                            <p>{{$t('TOSEU.eText268')}}</p>
                            <b>{{$t('TOSEU.eText269')}}</b>
                            <p>{{$t('TOSEU.eText270')}}</p>
                            <p style="text-align: right;">
                                <a class="link" href="/printable-terms-eu" onclick='return window.open("/printable-terms-eu","terms-of-service-printable","width=800,height=600,menubar=no,toolbar=no,personalbar=no,status=no,scrollbars").print(),!1'>
                                    {{$t('TOSEU.eText271')}}
                                </a>
                            </p>
                        </div>
                    </div>
                    </div> -->
                </div>
        </section>
    </div>
</template>
<script>
import { myStore } from "@/store/pinia-store";
import VuePdfEmbed from 'vue-pdf-embed'
export default {
    setup() {
        const store = myStore();
        return { store };
    },
  data() {
    return {
        show : false,
        loader : false
    };
  },
  components : {
    VuePdfEmbed
  },
  methods:{
    Load(e){
        if(e){
            this.loader = false
        }
    }
  },
  mounted(){
    setTimeout(()=> {
        this.show = true
    },1000)
  },
  created(){
    this.loader = true
  }
};
</script>